import { render, staticRenderFns } from "./ReservaList.vue?vue&type=template&id=0b693dfc&scoped=true"
import script from "./ReservaList.vue?vue&type=script&lang=js"
export * from "./ReservaList.vue?vue&type=script&lang=js"
import style0 from "./ReservaList.vue?vue&type=style&index=0&id=0b693dfc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b693dfc",
  null
  
)

export default component.exports